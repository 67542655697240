<template>
  <section id="background-bottom" class="background-bottom-section">
    <svg class="display-it" viewBox="0 0 1440 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M 0,77.86478 555.743,11.83308 C 667.342,-4.0643195 780.64,-3.9428195 892.206,12.19378 L 1440,77.86478 v 22 H 0 Z"
        fill="#FFF"
      />
    </svg>
  </section>
</template>

<script>
export default {
  name: "BackgroundBottom",
}
</script>

<style lang="scss" scoped>
.display-it {
  display: block;
}
</style>
